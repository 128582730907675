import React from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'

import star from '../../assets/icons/star2.svg'
import group from '../../assets/icons/group.svg'
import lightning from '../../assets/icons/lightning.svg'
import { device } from '../../style/variables'
import dots from '../../assets/images/home/dots.svg'
import image1 from '../../assets/images/home/block2.jpeg'
import image2 from '../../assets/images/home/block2bis.jpeg'

const Container = styled.div`
  margin: auto;
  max-width: 1140px;
  width: 100%;
  z-index: 2;
  margin-bottom: 100px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  @media ${device.smartphone} {
    align-items: center;
    width: 100%;
    margin-bottom: 60px;
  }
`

const Title = styled.h2`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  color: #001e3f;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  text-align: center;

  @media ${device.desktop} {
    text-align: left;
    margin-left: 3rem;
  }
`

const Content = styled.div`
  display: flex;
  @media ${device.smartphone} {
    max-width: 100%;
    flex-direction: column-reverse;
  }

  @media ${device.tablet} {
    justify-content: center;
  }
`

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.smartphone} {
    align-items: center;
    max-width: 100%;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  @media ${device.smartphone} {
    justify-content: center;
  }
`

const Text = styled.div`
  max-width: 455px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  color: #7c8c9f;
  @media ${device.smartphone} {
    max-width: 60%;
  }
`

const Separator = styled.div`
  width: 4px;
  height: 65px;
  background: #ecf0fa;
  border-radius: 50px;
  margin-right: 20px;
  @media ${device.smartphone} {
    display: none;
  }
`

const Icon = styled.div`
  min-width: 45px;
  max-width: 45px;
  margin-right: 1rem;
  @media ${device.smartphone} {
    margin-right: 1.5rem;
    & > * {
      min-width: 35px;
      max-width: 35px;
    }
  }
`

const Bandeau = styled.div`
  align-items: center;
  background: #ffffff;
  border-left: 6px solid #0e47c1;
  border-radius: 9.00563px;
  box-shadow: 0px 9.00563px 27.0169px rgba(0, 0, 0, 0.1);
  color: #0e47c1;
  display: flex;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  height: 77px;
  justify-content: center;
  line-height: 25px;
  margin-bottom: 30px;
  max-width: 75%;
  padding: 35px;

  @media ${device.tablet} {
    max-width: 100%;
    width: 540px;
    margin-bottom: 50px;
    margin-left: 3rem;
  }
`

const Items = styled.div`
  @media ${device.desktop} {
    margin-left: 3rem;
  }
`

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 16px;
  width: 100%;
  margin-top: -30px;
  margin-left: 2rem;

  @media ${device.smartphone} {
    width: 100%;
    margin: 0px;
    padding: 0px;
  }
`

const Background = styled.img`
  z-index: 0;
  position: absolute;
  top: 0px;
  left: 0px;
`

const Image1 = styled.img`
  border-radius: 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  z-index: 2;
  position: absolute;
  top: 40px;
  left: 40px;
  width: 320px;
`

const Image1mobile = styled.div`
  width: 100%;
  height: 200px;
  background: url(${image1}) no-repeat center;
  background-size: cover;
  margin-bottom: 52px;

  @media ${device.tablet} {
    height: 360px;
  }

  @media ${device.desktop} {
    display: none;
  }
`

const Image2 = styled.img`
  border-radius: 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  z-index: 1;
  position: absolute;
  bottom: 10px;
  right: 40px;
  width: 220px;
`

const Item = (icon, text) => (
  <Row>
    <Icon>
      <SVG src={icon} />
    </Icon>
    <Separator />
    <Text>{text}</Text>
  </Row>
)

function Block2() {
  return (
    <Container>
      <Title>
        <b>Vendez, </b>bien accompagné
      </Title>
      <Image1mobile />
      <Content>
        <LeftContent>
          <Items>
            {Item(
              star,
              'Les agents Allowa : des super-agents implantés dans votre secteur, et dotés d’outils puissants pour optimiser leur recherche d’acquéreurs.'
            )}
            {Item(
              group,
              'Derrière votre agent, toute une équipe au service de votre projets : juristes, marketers, data-scientist, designers... On a toutes les compétences pour faire de votre vente une réussite.'
            )}
            {Item(
              lightning,
              'Des experts réactifs et disponibles. Par sms, tchat, e-mail ou bien téléphone. Vous êtes sur de nous trouver, vite, pour répondre à toutes vos questions.'
            )}
          </Items>
          <Bandeau>Service au succès : vous n’aurez aucun montant à avancer</Bandeau>
        </LeftContent>
        <RightContent className="desktop-only">
          <Background className="desktop-only" src={dots} />
          <Image1 className="desktop-only" src={image1} loading="lazy" />
          <Image2 className="desktop-only" src={image2} loading="lazy" />
        </RightContent>
      </Content>
    </Container>
  )
}

export default Block2
