import React from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'

import pencil from '../../assets/icons/pencil.svg'
import visibility from '../../assets/icons/visibility.svg'
import target from '../../assets/icons/target.svg'
import security from '../../assets/icons/security.svg'
import { device } from '../../style/variables'

const Container = styled.div`
  margin: auto;
  max-width: 1140px;
  width: 100%;
  z-index: 2;
  margin-bottom: 20px;
  border-radius: 8px;
  position: relative;
  top: -100px;
  margin-bottom: -100px;
  background: white;
  @media ${device.smartphone} {
    border-radius: 0px;
    position: initial;
    margin-bottom: 50px;
  }
`

const Square = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80.69px;
  height: 80.69px;
  background: #ffffff;
  border: 0.754138px solid #ff7057;
  box-sizing: border-box;
  box-shadow: 0px 1.50828px 6.0331px rgba(0, 0, 0, 0.12);
  border-radius: 6.0331px;
  margin-bottom: 50px;
  @media ${device.smartphone} {
    width: 60.69px;
    height: 60.69px;
    margin-bottom: 12px;
    & > * {
      width: 20px;
      height: 20px;
    }
  }
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${device.smartphone} {
    margin-bottom: 8px;
  }
`
const SubBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.p`
  font-family: Poppins;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  margin-bottom: 8px;
  color: #001e3f;
`

const Text = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  color: #888888;
  max-width: 232px;
`

const Content = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: nowrap;
  padding: 16px;
  padding-top: 32px;
  padding-bottom: 32px;
  @media ${device.smartphone} {
    flex-direction: column;
  }
`

const Divider = styled.div`
  width: 90%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin: auto;
`

const Column = (icon, title, text) => (
  <Block>
    <Square>
      <SVG src={icon} />
    </Square>
    <SubBlock>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </SubBlock>
  </Block>
)

function Block1() {
  return (
    <Container>
      <Content>
        {Column(
          pencil,
          'Personnalisation',
          'Un expert immobilier dédié vous accompagne tout au long de votre projet'
        )}
        {Column(
          visibility,
          'Visibilité',
          'Création et diffusion de votre annonce sur les plus grands sites immobiliers'
        )}
        {Column(
          target,
          'Précision',
          'Estimation du prix de votre logement avec nos outils data et une visite d’un expert'
        )}
        {Column(
          security,
          'Sécurité',
          'Vérrouillage de votre dossier de vente par une équipe de juristes immobiliers'
        )}
      </Content>
      <Divider />
    </Container>
  )
}

export default Block1
