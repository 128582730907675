import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery, navigate } from 'gatsby'

import { colours, device } from '../../style/variables'
import routes from '../../utils/routes'
import BlogCard from './BlogCard'
import BannerCard from '../common/BannerCard'

const Container = styled.div`
  max-width: 1140px;
  margin: auto;
  margin-bottom: 100px;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: bottom;
`

const Articles = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 40px;
  @media ${device.smartphone} {
    overflow: auto;
    justify-content: flex-start;
    padding-bottom: 10px;
  }
`

const Title = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 25px;
  text-align: center;
  color: #001e3f;

  &#mainTitle {
    font-size: 20px;
  }
`

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  align-items: center;
  margin-top: 60px;
  position: relative;
`

function News() {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulBlogPost(
          limit: 3
          sort: { fields: createdAt, order: DESC }
          filter: { node_locale: { eq: "fr-FR" } }
        ) {
          nodes {
            slug
            title
            createdAt(formatString: "DD MMMM, YYYY", locale: "fr")
            headerimage {
              description
              fluid(maxWidth: 400) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    `
  )

  return (
    <Container>
      <Title id='mainTitle'>Les actualités Allowa</Title>
      <Articles>
        <BlogCard
          image={data.allContentfulBlogPost.nodes[0].headerimage.fluid}
          alt={data.allContentfulBlogPost.nodes[0].headerimage.description}
          date={data.allContentfulBlogPost.nodes[0].createdAt}
          title={data.allContentfulBlogPost.nodes[0].title}
          slug={data.allContentfulBlogPost.nodes[0].slug}
        />
        <BlogCard
          image={data.allContentfulBlogPost.nodes[1].headerimage.fluid}
          alt={data.allContentfulBlogPost.nodes[1].headerimage.description}
          date={data.allContentfulBlogPost.nodes[1].createdAt}
          title={data.allContentfulBlogPost.nodes[1].title}
          slug={data.allContentfulBlogPost.nodes[1].slug}
        />
        <BlogCard
          image={data.allContentfulBlogPost.nodes[2].headerimage.fluid}
          alt={data.allContentfulBlogPost.nodes[2].headerimage.description}
          date={data.allContentfulBlogPost.nodes[2].createdAt}
          title={data.allContentfulBlogPost.nodes[2].title}
          slug={data.allContentfulBlogPost.nodes[2].slug}
        />
      </Articles>
      <Bottom>
        <BannerCard
          title={'Vendre son logement en 8 étapes'}
          content={' Comment vendre son logement dans les règles de l’art en suivant toutes les étapes, on vous explique toutes les démarches ici'}
          buttonLabel={'Découvrir'}
          onClick={() => navigate(routes.etapes)}
          foregroundColor={colours.lightBlue}
          backgroundColor={colours.orangeBg}
          fontColor="40566F"
          invert
        />
      </Bottom>
    </Container>
  )
}

export default News
