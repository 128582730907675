import * as React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'
import Seo from '../components/seo'
import Hero from '../components/home/Hero'
import Block1 from '../components/home/Block1'
import Block2 from '../components/home/Block2'
import Block3 from '../components/home/Block3'
import Block4 from '../components/home/Block4'
import Block5 from '../components/home/Block5'
import Properties from '../components/home/Properties'
import News from '../components/home/News'
import Faq from '../components/home/Faq'

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <meta name="google-site-verification" content="s48zF1yRh16uSueXkqi3GI9st_0lrRKkA0tjNNWX78g" />
      </Helmet>
      <Seo
        title={`Accueil`}
        description="Vendez votre logement, simplement."
        keywords={`vendre, vendre logement, estimation, estimer prix, immobilier, estimer prix maison, estimer prix appartement`}
      />
      <Hero />
      <Block1 />
      <Block2 />
      <Block3 />
      <Block4 />
      <Properties />
      <Block5 />
      <News />
      <Faq />
    </Layout>
  )
}

export default IndexPage
