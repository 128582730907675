import React from 'react'
import styled from 'styled-components'

import RatingCard from './RatingCard'
import rating1 from '../../assets/images/home/rating1.png'
import rating2 from '../../assets/images/home/rating2.png'
import rating3 from '../../assets/images/home/rating3.png'
import Button from '../common/Button'
import quote from '../../assets/images/home/quote.svg'
import bg from '../../assets/images/home/rating_bg.svg'
import { device } from '../../style/variables'

const LeftContent = styled.div`
  max-width: 450px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 50px;
  position: relative;
  margin-left: 75px;
  margin-right: 100px;
  & > * {
    margin-bottom: 12px;
  }
  @media ${device.smartphone} {
    align-items: center;
    padding: 0px;
    margin: 0px;
  }
`

const RightContent = styled.div`
  width: 300px;
  border-left: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin: auto;
  position: relative;
  @media ${device.smartphone} {
    align-items: center;
  }
`

const Flex = styled.div`
  display: flex;
  margin: auto;
  margin-bottom: 16px;
  width: 1056px;
  @media ${device.smartphone} {
    flex-direction: column-reverse;
    max-width: 95%;
    justify-content: center;
    align-items: center;
  }
}
`

const Title = styled.div`
  width: 301px;
  height: 82px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 37px;
  color: #001e3f;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 12px;
  @media ${device.smartphone} {
    text-align: center;
  }
`

const SubTitle = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #001e3f;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 32px;
  @media ${device.smartphone} {
    text-align: center;
    margin-bottom: 22px;
  }
`

const Quotes = styled.div`
  position: absolute;
  display: flex;
  left: -40px;
  top: 25px;
`

const Background = styled.img`
  position: absolute;
  min-width: 694px;
`

function Block4() {
  return (
    <Flex>
      <LeftContent>
        <Background className="desktop-only" src={bg} />
        <RatingCard
          author="Erminia V."
          comment="Rapide, efficace et professionnel."
          date={'13/06/2021'}
          rating={5}
          picture={rating1}
        />
        <RatingCard
          author="Simon D."
          comment="Si je devais qualifier mon expérience avec l’agence Allowa, ce serait PARFAIT."
          date={'13/07/2020'}
          rating={5}
          picture={rating2}
        />
        <RatingCard
          author="Aymene B."
          comment="Une assistance rapide lors de la création du compte pour répondre à mes questions. Les agents sont sympa et accueillants."
          date={'12/11/2020'}
          rating={5}
          picture={rating3}
        />
        <Button
          className="mobile-only"
          blue
          label="Voir tous les avis"
          onClick={() =>
            window.open(
              'https://www.google.com/search?q=allowa+&client=firefox-b-d&sxsrf=ALeKk0062iNQrtAvI_nzrNdPMSVpPbTqWw%3A1629205636063&ei=hLQbYc-UA7CalwSV24NY&oq=allowa+&gs_lcp=Cgdnd3Mtd2l6EAMyBAgjECcyBAgAEAoyBAgAEAoyBAgAEAoyBAgAEAoyBwguEIAEEAoyBwgAEIAEEAoyBwgAEIAEEAoyBwgAEIAEEAoyBwguEIAEEAo6BwgAEEcQsAM6EQgAELADEIoDELcDENQDEOUCSgQIQRgAUIgVWIgVYI8WaANwAngAgAHLAYgBtwKSAQUwLjEuMZgBAKABAcgBCsABAQ&sclient=gws-wiz&ved=0ahUKEwjP5s_5j7jyAhUwzYUKHZXtAAsQ4dUDCA0&uact=5#lrd=0x47e66f915a2d897d:0xa5e484e7e296c4d3,1,,,',
              '_blank'
            )
          }
        />
      </LeftContent>
      <RightContent>
        <Quotes className="desktop-only">
          <img src={quote} />
          <img src={quote} />
        </Quotes>
        <Title>Nos clients en parlent mieux que nous</Title>
        <SubTitle className="desktop-only">et on les remercie de leur confiance</SubTitle>
        <Button
          className="desktop-only"
          blue
          label="Voir tous les avis"
          onClick={() =>
            window.open(
              'https://www.google.com/search?q=allowa+&client=firefox-b-d&sxsrf=ALeKk0062iNQrtAvI_nzrNdPMSVpPbTqWw%3A1629205636063&ei=hLQbYc-UA7CalwSV24NY&oq=allowa+&gs_lcp=Cgdnd3Mtd2l6EAMyBAgjECcyBAgAEAoyBAgAEAoyBAgAEAoyBAgAEAoyBwguEIAEEAoyBwgAEIAEEAoyBwgAEIAEEAoyBwgAEIAEEAoyBwguEIAEEAo6BwgAEEcQsAM6EQgAELADEIoDELcDENQDEOUCSgQIQRgAUIgVWIgVYI8WaANwAngAgAHLAYgBtwKSAQUwLjEuMZgBAKABAcgBCsABAQ&sclient=gws-wiz&ved=0ahUKEwjP5s_5j7jyAhUwzYUKHZXtAAsQ4dUDCA0&uact=5#lrd=0x47e66f915a2d897d:0xa5e484e7e296c4d3,1,,,',
              '_blank'
            )
          }
        />
      </RightContent>
    </Flex>
  )
}

export default Block4
