import React from 'react'
import styled from 'styled-components'

import { device, colours } from '../../style/variables'
import PropertyCard from '../common/PropertyCard'
import property1 from '../../assets/images/property-1.png'
import property2 from '../../assets/images/property-2.png'
import property3 from '../../assets/images/property-3.png'

const Container = styled.div`
  justify-content: center;
  margin: 2rem auto 0 auto;
  padding: 0;

  @media ${device.tablet} {
    margin-top: 3rem;
    max-width: 1140px;
  }
`

const FirstBlock = styled.div`
  @media ${device.desktop} {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
`

const TopRow = styled.div`
  display: flex;
  justify-content: center;
`

const Title = styled.div`
  color: ${colours.blue};
  font-size: 18px;
  font-weight: 600;
  margin-left: 1rem;
  text-align: center;

  @media ${device.tablet} {
    margin-left: 2rem;
  }

  @media ${device.desktop} {
    margin-left: 2.6rem;
    font-size: 20px;
    font-weight: 700;
  }
`

const CardsBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  overflow: auto;
  text-align: center;
  width: 100%;

  @media ${device.tablet} {
    margin: 1rem auto;
  }

  @media ${device.desktop} {
    max-width: 1140px;
    justify-content: space-evenly;
  }
`

const CardContainer = styled.div`
  margin: 0.9rem 0.8rem;

  &:first-child {
    margin-left: 1rem;
  }

  &:last-child {
    margin-right: 1rem;
  }

  @media ${device.tablet} {
    margin: 1rem;

    &:first-child {
      margin-left: 2rem;
    }

    &:last-child {
      margin-right: 2rem;
    }
  }

  @media ${device.desktop} {
    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`

function Properties() {
  const properties = [
    {
      image: `${property1}`,
      price: '200 000',
      isHouse: false,
      roomNumber: 5,
      surface: 103,
      location: `Metz`,
      tags: ['Orienté Sud', 'Grande terrasse'],
    },
    {
      image: `${property2}`,
      price: '149 000',
      isHouse: false,
      roomNumber: 4,
      surface: 75,
      location: `Metz`,
      tags: ['Refait à neuf', 'Grande terrasse'],
    },
    {
      image: `${property3}`,
      price: '126 000',
      isHouse: true,
      roomNumber: 5,
      surface: 110,
      location: `Belleville`,
      tags: ['Grand Jardin'],
    },
  ]
  return (
    <Container>
      <FirstBlock>
        <TopRow>
          <Title>Derniers biens vendus récemment</Title>
        </TopRow>
        <CardsBlock>
          {properties.map(property => (
            <CardContainer>
              <PropertyCard
                image={property.image}
                price={property.price}
                isHouse={property.isHouse}
                roomNumber={property.roomNumber}
                surface={property.surface}
                location={property.location}
                tags={property.tags}
              />
            </CardContainer>
          ))}
        </CardsBlock>
      </FirstBlock>
    </Container>
  )
}

export default Properties
