import React from 'react'
import styled from 'styled-components'

import Button from '../common/Button'
import france from '../../assets/images/home/france_.png'
import { colours, device } from '../../style/variables'
import { navigate } from 'gatsby'

const LeftContent = styled.div`
  max-width: 450px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 50px;
  margin-right: 50px;
  @media ${device.smartphone} {
    width: 100%;
    padding: 0px;
    align-items: center;
    margin: auto;
  }
`

const RightContent = styled.div`
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 40px;
  @media ${device.smartphone} {
    width: 100%;
    padding: 0px;
  }
`
const Flex = styled.div`
  display: flex;
  margin: auto;
  margin-bottom: 16px;
  width: 1056px;
  @media ${device.smartphone} {
    flex-direction: column-reverse;
    max-width: 90%;
    align-items: center;
    margin-bottom: 50px;
  }
}
`

const Title = styled.div`
  max-width: 439px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 51px;
  color: #001e3f;
  margin-bottom: 8px;
`

const SubTitle = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #001e3f;
`

const Text = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  color: #7c8c9f;
  margin-bottom: 16px;

  @media ${device.desktop} {
    width: 439px;
  }
`

const Separator = styled.div`
  width: 58px;
  height: 7px;
  background: #ff7057;
  margin-bottom: 50px;
  margin-top: 25px;
`

const Image = styled.img`
  @media ${device.smartphone} {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`

const Block = styled.div`
  width: 444px;
  height: 245px;
  background: #ffffff;
  border: 1px solid #ff7057;
  box-sizing: border-box;
  box-shadow: 0px 9.00563px 27.0169px -9.00563px rgba(0, 0, 0, 0.1);
  border-radius: 9.00563px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 26px;
  margin-top: 30px;
  @media ${device.smartphone} {
    width: 100%;
    padding: 12px;
    height: auto;
  }
`

const BlockTitle = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 25px;
  text-align: center;
  color: ${colours.blue};
`

const BlockText = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  color: ${colours.blue};
  margin-top: 13px;
  margin-bottom: 31px;
  padding: 0 2rem;
`

function Block5() {
  return (
    <Flex>
      <LeftContent>
        <Image src={france} loading="lazy" />
        <Block>
          <BlockTitle>Le saviez-vous ?</BlockTitle>
          <BlockText>
            Sur 3 Français qui essaient de vendre leur logement par eux-même, 2 finissent par faire
            appel à un professionnel de l’immobilier
          </BlockText>
          <Button
            orange
            label="Découvrir Pourquoi"
            onClick={() =>
              navigate(
                '/blog/Pourquoi-confier-la-vente-de-logement-a-des-professionnels-de-l-immobilier'
              )
            }
          />
        </Block>
      </LeftContent>
      <RightContent>
        <Title>Des agents dans toute la France,</Title>
        <SubTitle>et une plateforme qui booste leur performance</SubTitle>
        <Separator />
        <Text>
          Allowa c’est un réseau d’agents indépendants selectionnés et formés pour réaliser des
          ventes avec la meilleure qualité de service; à la fois auprès de vous mais aussi auprès de
          vos futurs clients.{' '}
        </Text>
        <Text>
          {' '}
          Avec notre plateforme en ligne, nous facilitons la captation et la conversion de vos
          acquéreurs, en les accompagnant étape par étape.{' '}
        </Text>
        <Text>
          {' '}
          De votre côté, suivez l’évolution de votre vente en suivant chaque action réalisée, et
          recevez vos offres directement en ligne en temps réel.
        </Text>
      </RightContent>
    </Flex>
  )
}

export default Block5
