import React from 'react'
import styled from 'styled-components'
import Rating from '@material-ui/lab/Rating'
import { colours, device } from '../../style/variables'

import bg from '../../assets/images/home/rating_picture_bg.svg'

const Container = styled.div`
  width: 410px;
  height: 160px;
  background: #ffffff;
  box-shadow: 0px 4px 27.0169px -9.00563px rgba(0, 0, 0, 0.25);
  border-radius: 9.00563px;
  padding: 25px;
  padding-left: 75px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  position: relative;

  @media ${device.smartphone} {
    max-width: 90vw;
    padding: 20px;
  }
`

const Comment = styled.div`
  max-width: 310px;
  max-height: 70px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: ${colours.grey};
  margin: auto;
  margin-left: 0;
`

const BottomPart = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
`

const Author = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
`

const Ratings = styled.div`
  display: flex;
`

const Photo = styled.img`
  border-radius: 100px;
  position: absolute;
  height: 83px;
  width: 83px;
  left: 0px;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`

const Background = styled.img`
  position: absolute;
  left: -3px;
  top: 50%;
  transform: translate(-50%, -46%);
  z-index: 1;
`

const Date = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  color: ${colours.grey};
  margin-left: 8px;
`

function RatingCard({ picture, rating, date, comment, author }) {
  return (
    <Container>
      <Photo className="desktop-only" src={picture} />
      <Background className="desktop-only" src={bg} />
      <Comment>{comment}</Comment>
      <BottomPart>
        <Author>{author}</Author>
        <Ratings>
          <Rating size="small" value={rating} readOnly />
          <Date>{date}</Date>
        </Ratings>
      </BottomPart>
    </Container>
  )
}

export default RatingCard
