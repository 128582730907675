import React from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import { colours, device } from '../../style/variables'

type BlogProps = {
  image?: any
  alt?: string
  date?: string
  title?: string
  slug?: string
}

const StyledCard = styled(Card)`
  cursor: pointer;
  width: 330px !important;
  height: 350px !important;
  box-shadow: 0px 8.35644px 25.0693px -8.35644px rgba(0, 0, 0, 0.1) !important;
  border-radius: 20px !important;
  position: relative;

  &:hover {
    -webkit-filter: brightness(0.97);
    filter: brightness(0.97);
  }

  @media ${device.smartphone} {
    min-width: 290px !important;
    max-width: 290px !important;
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
`

const Title = styled.p`
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 21px !important;
  color: #939393 !important;
  margin-bottom: 12px !important;
`

const Content = styled.p`
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 21px !important;
  color: ${colours.blue} !important;
  margin-bottom: 0px !important;
`

const Action = styled(Button)`
  font-family: 'Poppins';
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 21px !important;
  letter-spacing: 0;
  color: ${colours.orange} !important;
  text-transform: none !important;
  margin-left: 16px !important;
  position: absolute;
  bottom: 14px;
`

const MainContent = styled(CardContent)`
  padding: 25px !important;
`

function BlogCard(props: BlogProps) {
  return (
    <StyledCard onClick={() => navigate(`/blog/${props.slug}`)}>
      <div>
        <CardMedia
          component="img"
          alt={props.alt}
          height="158"
          image={props.image.src}
          title={props.title}
          style={{ marginBottom: 8 }}
        />
        <MainContent>
          <Title>{props.date}</Title>
          <Content>{props.title}</Content>
        </MainContent>
      </div>
      <CardActions>
        <Action onClick={() => navigate(`/blog/${props.slug}`)} size="small">Voir plus</Action>
      </CardActions>
    </StyledCard>
  )
}

export default BlogCard
