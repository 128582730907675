import React from 'react'
import styled from 'styled-components'

import { colours, device } from '../../style/variables'

const Container = styled.div`
  margin: auto;
  width: 1056px;
  height: 339px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 9.00563px 27.0169px -9.00563px rgba(0, 0, 0, 0.1);
  border-radius: 9.00563px;
  display: flex;
  @media ${device.smartphone} {
    width: 90%;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
`

const LeftContent = styled.div`
  max-width: 417px;
  height: 100%;
  background: #0e47c1;
  border: 1px solid #afc9ff;
  box-sizing: border-box;
  border-radius: 9.00563px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 50px;
  @media ${device.smartphone} {
    max-width: 100%;
    border-radius: 9.00563px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`

const RightContent = styled.div`
  border: 1px solid #afc9ff;
  width: 100%;
  height: 100%;
  border-top-right-radius: 9.00563px;
  border-bottom-right-radius: 9.00563px;
  border-left: none;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${device.smartphone} {
    max-width: 100%;
    border: 1px solid #afc9ff;
    border-radius: 9.00563px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-top: none;
    padding: 12px;
  }
`

const List = styled.ul`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  color: #001e3f;

  span {
    font-size: 11px;
  }

  li::marker {
    content: '✓  ';
  }
`

const Title = styled.h2`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 37px;
  color: #ffffff;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  width: 270px;
`

const SubTitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin: 0px;
`

const Separator = styled.div`
  width: 58px;
  height: 7px;
  background: #ff7057;
  align-self: flex-start;
  margin-top: -45px;
  @media ${device.smartphone} {
    margin: 0px;
    align-self: center;
    margin-top: -15px;
    margin-bottom: 20px;
  }
`
const Flex = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

const Tags = styled.div`
  display: flex;
  margin: auto;
  width: 1056px;
  margin-top: 18px;
  @media ${device.smartphone} {
    max-width: 90%;
    flex-wrap: wrap;
  }
`

const Tag = styled.div<{ success?: boolean }>`
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid ${props => (props.success ? '#51D1AD' : '#ff7057')};
  color: ${props => (props.success ? '#484848' : '#FF7057 !important')};
  text-decoration: ${props => (props.success ? 'none' : 'line-through')};
  box-sizing: border-box;
  box-shadow: 0px 9.00563px 27.0169px -9.00563px rgba(0, 0, 0, 0.1);
  border-radius: 9.00563px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${colours.blue};
  width: fit-content;
  margin-right: 12px;
  &:last-of-type {
    margin-right: 0px;
  }
  flex: 1;
  @media ${device.smartphone} {
    min-width: 110px;
    max-width: 180px;
    margin-bottom 12px;
  }
`

function Block3() {
  return (
    <Flex>
      <Container>
        <LeftContent>
          <Title>Un service total depuis votre canapé</Title>
          <Separator />
          <SubTitle>On s'occupe de tout, sans mention cachée</SubTitle>
        </LeftContent>
        <RightContent>
          <List>
            <li> Estimation scientifique du prix de votre logement</li>
            <li>Shooting photos et retouches professionnelles</li>
            <li>Diffusion des annonces sur les plus grands portails immobiliers</li>
            <li>Recherche d’acquéreurs et qualification des prospects</li>
            <li>Organisation et réalisation des visites sur place</li>
            <li>Vérification solvabilité des acquéreurs</li>
            <li>Gestion et analyse des offres</li>
            <li>Suivi de la commercialisation sur un espace dédié en ligne</li>
            <li>
              Rédaction et signature du compromis en ligne <span>(si non-notariée)</span>
            </li>
            <li>Accompagnement et protection juridique</li>
          </List>
        </RightContent>
      </Container>
      <Tags>
        <Tag>Agence Low cost</Tag>
        <Tag success>Full Service</Tag>
        <Tag success>High Performance</Tag>
        <Tag success>Total-accompagnement</Tag>
      </Tags>
    </Flex>
  )
}

export default Block3
