import React, { useState } from 'react'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import SVG from 'react-inlinesvg'

import faq from '../../assets/images/home/faq.svg'
import chevronBottom from '../../assets/icons/chevron-bottom.svg'
import chevronTop from '../../assets/icons/chevron-top.svg'
import Button from '../common/Button'
import { colours, device } from '../../style/variables'
import { Link, navigate } from 'gatsby'

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    padding: '0 1rem',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.15);',
    marginBottom: 0,
    minHeight: 80,
    padding: 0,
    '&$expanded': {
      minHeight: 80,
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: '1rem 0',
  },
}))(MuiAccordionDetails)

const Background = styled.div`
  @media ${device.desktop} {
    background-image: linear-gradient(white 35%, ${colours.lightBlue} 0%);
  }
`

const Container = styled.div`
  width: 1056px;
  height: 100%;
  background: #ffffff;
  border: 1px solid #afc9ff;
  box-sizing: border-box;
  box-shadow: 0px 9.00563px 27.0169px -9.00563px rgba(0, 0, 0, 0.1);
  border-radius: 9.00563px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin: auto;
  padding: 45px;
  padding-top: 68px;
  padding-bottom: 85px;
  display: flex;

  @media ${device.smartphone} {
    flex-direction: column-reverse;
    max-width: 90%;
    padding: 25px;
    margin-bottom: 75px;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
  }

  @media ${device.desktop} {
    border-bottom: 0;
  }
`

const LeftContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 100%;

  @media ${device.desktop} {
    margin: auto;
    max-width: 350px;
  }
`

const Image = styled.img`
  margin-top: 40px;
`

const SubTitle = styled.div`
  color: #001e3f;
  font-size: 18px;
  font-weight: bold;
  line-height: 27px;
  margin-bottom: 35px;
  margin-top: 48px;
`

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${device.desktop} {
    margin-left: 2rem;
  }
`

const Title = styled.div`
  color: #001e3f;
  font-size: 23px;
  font-weight: bold;
  line-height: 37px;
  margin-left: 1rem;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);

  @media ${device.desktop} {
    font-size: 25px;
  }
`

const Separator = styled.div`
  width: 58px;
  height: 7px;
  background: #ff7057;
  margin-top: 21px;
  margin-bottom: 36px;
  margin-left: 1rem;
`

const Label = styled.div`
  color: #001e3f;
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
`

const StyledSVG = styled(SVG)`
  min-width: 24px;
`

const Text = styled.div`
  color: ${colours.grey};
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 25px;
`

function Faq() {
  const [section, setSection] = useState('section-1')

  const handleChange = panel => (event, newExpanded) => {
    setSection(newExpanded ? panel : false)
  }

  return (
    <Background>
      <Container>
        <LeftContent>
          <Image src={faq} />
          <SubTitle>Je veux réussir ma vente</SubTitle>
          <Button orange label="Démarrer mon projet" onClick={() => navigate('/estimation')} />
        </LeftContent>
        <RightContent>
          <Title>Les réponses à vos questions</Title>
          <Separator />
          <div>
            <Accordion expanded={section === 'section-1'} onChange={handleChange('section-1')}>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <Label>Quelle est la différence avec une agence traditionnelle ?</Label>
                <StyledSVG src={section === 'section-1' ? chevronTop : chevronBottom} />
              </AccordionSummary>
              <AccordionDetails>
                <Text>
                  Allowa n’a pas de vitrine physique mais a mis tous ses moyens dans des outils
                  facilitant les interactions entre conseillers, vendeurs et acquéreurs ; pour des
                  transactions plus rapides, sécurisées juridiquement, au prix juste, avec un réel
                  accompagnement de nos agents sur le terrain.
                </Text>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={section === 'section-2'} onChange={handleChange('section-2')}>
              <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                <Label>Qui gère les visites du bien ?</Label>
                <StyledSVG src={section === 'section-2' ? chevronTop : chevronBottom} />
              </AccordionSummary>
              <AccordionDetails>
                <Text>
                  On s'occupe de tout. Votre agent Allowa pré-filtre les personnes intéressées par
                  votre bien et leur fait visiter, selon vos disponibilités si vous vivez dans le
                  logement. Après chaque visite, votre agent vous communique un compte-rendu afin de
                  suivre en temps réel les réflexions des acheteurs potentiels.
                </Text>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={section === 'section-3'} onChange={handleChange('section-3')}>
              <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                <Label>Qui s'occupe des démarches administratives ?</Label>
                <StyledSVG src={section === 'section-3' ? chevronTop : chevronBottom} />
              </AccordionSummary>
              <AccordionDetails>
                <Text>
                  On s'en occupe aussi. Deux experts pour vous accompagner sur la constitution du
                  dossier : le conseiller immobilier à proximité, qui assure la commercialisation de
                  votre bien, et l’expert administratif à distance qui sécurise votre vente et
                  facilite le processus avec les notaires. Ils sont tous les deux joignables en
                  direct pour répondre à toutes vos questions.
                </Text>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={section === 'section-4'} onChange={handleChange('section-4')}>
              <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                <Label>Les services liés à la vente sont-ils inclus dans vos honoraires ?</Label>
                <StyledSVG src={section === 'section-4' ? chevronTop : chevronBottom} />
              </AccordionSummary>
              <AccordionDetails>
                <Text>
                  Tous les services que nous communiquons sont inclus dans nos honoraires, sans coût
                  additionnel pour vous, de l’estimation initiale à la signature finale en passant
                  par la prise en charge des visites acheteur. Nous engageons des frais pendant la
                  vente, mais ces honoraires ne seront dus que si Allowa vend votre bien, après la
                  signature finale de l’acte de vente.
                </Text>
              </AccordionDetails>
            </Accordion>
          </div>
        </RightContent>
      </Container>
    </Background>
  )
}

export default Faq
