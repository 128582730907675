import React, { useState } from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { Theme, makeStyles } from '@material-ui/core/styles'
import PlacesAutocomplete, { getLatLng, geocodeByAddress } from 'react-places-autocomplete'
import { colours, device } from '../../style/variables'

import Button from '../common/Button'
import hero from '../../assets/images/home/hero.jpg'
import heroMobile from '../../assets/images/home/hero_mobile.jpg'
// Assets
import pin from '../../assets/icons/mappin.svg'
import google from '../../assets/icons/google-rating.svg'
import pj from '../../assets/images/home/pages_jaunes.png'
import { navigate } from 'gatsby'

const Container = styled.div`
  color: ${colours.offwhite};
  display: flex;
  flex-direction: column;
  margin: -6rem auto 0 auto;
  padding-top: 8rem;
  height: 670px;
  background-position: center;
  position: relative;
  width: 1140px;
  @media ${device.smartphone} {
    width: 100%;
    padding-top: 6rem;
  }
`
const Background = styled.div`
  background: url('${hero}');
  // background-position: bottom 20px left -320px;
  background-size: cover;
  position: relative;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.15);
  }

  @media ${device.tablet} {
    background-position: bottom 60px right -320px;
  }

  @media ${device.desktop} {
    background-position: 0% 100%;
  }

  @media ${device.smartphone} {
    background: url('${heroMobile}');
  }
`

const Ratings = styled.div`
  width: fit-content;
  height: 51px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4.30132px;
  color: white;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
  & > * {
    margin-left: 16px;
  }
  @media ${device.smartphone} {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    border-radius: 0px;
    margin: auto;
    padding: 0px;
    justify-content: space-evenly;
    & > * {
      margin-left: 0px;
    }
  }
`

const Rating = styled.p`
  margin-bottom: 0px;
`

const Content = styled.div`
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  @media ${device.smartphone} {
    margin: 0;
    padding: 30px;
  }
`

const PreTitle = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  display: flex;
  align-items: center;
  @media ${device.smartphone} {
    max-width: 80%;
  }
`

const OrangeVertical = styled.div`
  width: 4px;
  height: 31px;
  background: #ff7057;
  margin-right: 20px;
  @media ${device.smartphone} {
    height: 40px;
  }
`

const Title = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  color: #ffffff;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  max-width: 470px;
`

const Label = styled.p`
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  max-width: 260px;
  color: #ffffff;

  @media ${device.tablet} {
    display: block;
    max-width: 100%;
  }
`

const SearchContainer = styled.div`
  display: flex;
  @media ${device.smartphone} {
    flex-direction: column;
  }
`
const useStyles = makeStyles((theme: Theme) => ({
  inputText: {
    border: '1px solid #DFE4EC',
    borderRadius: 10,
    color: '#001E3F',
    fontFamily: 'Poppins, sans-serif',
    fontSize: 15,
    fontWeight: 500,
    height: 65,
    outline: 'none',
    padding: '0 70px 0 30px',
    textTransform: 'none',
    width: 550,
    paddingLeft: 15,
    paddingRight: 50,
    '&:focus': {
      border: '1px solid #7C8C9F',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  inputLabel: { position: 'relative' },
  inputIcon: {
    position: 'absolute',
    right: 15,
    top: 0,
  },
  formField: {
    position: 'relative',
    maxWidth: 550,
    marginRight: 12,
  },
  suggestionsBox: {
    position: 'absolute',
    top: 65,
    width: '100%',
    borderRadius: 6,
    zIndex: 5,
    fontSize: 14,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      width: '100%',
    },
  },
}))

function Hero() {
  const classes = useStyles()
  const [address, setAddress] = useState('')
  const [latLng, setLatLng] = useState({
    lat: 0,
    lon: 0,
  })
  const handleSelect = async value => {
    const results = await geocodeByAddress(value)
    const latLng = await getLatLng(results[0])
    setAddress(value)
    setLatLng({ lat: latLng.lat, lon: latLng.lng })
  }

  const searchOptions = {
    componentRestrictions: { country: ['fr'] },
  }

  return (
    <Background>
      <Container>
        <Content>
          <PreTitle>
            <OrangeVertical />
            Agence immobilière nouvelle génération
          </PreTitle>
          <Title>Vendez votre logement, simplement.</Title>
          <Label>Estimez le prix de votre logement</Label>
          <SearchContainer>
            <PlacesAutocomplete
              value={address}
              onChange={value => setAddress(value)}
              onSelect={handleSelect}
              searchOptions={searchOptions}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div className={classes.formField}>
                  <form>
                    <label className={classes.inputLabel}>
                      <input
                        placeholder="Votre adresse"
                        {...getInputProps()}
                        className={classes.inputText}
                      />
                      <img src={pin} height={25} className={classes.inputIcon} />
                    </label>
                  </form>
                  <div className={classes.suggestionsBox}>
                    {suggestions.map((suggestion, index) => {
                      const style = {
                        backgroundColor: index % 2 === 0 ? '#e6ecf8' : '#ffffff',
                        borderTopLeftRadius: index === 0 ? 5 : 0,
                        borderTopRightRadius: index === 0 ? 5 : 0,
                        borderBottomRightRadius: index === suggestions.length - 1 ? 5 : 0,
                        borderBottomLeftRadius: index === suggestions.length - 1 ? 5 : 0,
                        //   borderRadius: 5,
                        color: suggestion.active ? '#001E3F' : '#67727D',
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: 500,
                        //   maxWidth: 570,
                        padding: '10px 10px',
                        zIndex: 1,
                      }
                      return (
                        <div {...getSuggestionItemProps(suggestion, { style })}>
                          {suggestion.description}
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            <Button
              className="desktop-only"
              big
              orange
              label="Estimer gratuitement"
              onClick={() =>
                navigate(`/estimation?lat=${latLng.lat}&lon=${latLng.lon}&address=${address}`)
              }
            />
            <Button
              className="mobile-only"
              orange
              label="Estimer gratuitement"
              onClick={() =>
                navigate(`/estimation?lat=${latLng.lat}&lon=${latLng.lon}&address=${address}`)
              }
            />
          </SearchContainer>
          <Ratings>
            <img src={pj} />
            <Rating>4.8/5</Rating>
            <SVG src={google} />
            <Rating>4.8/5</Rating>
          </Ratings>
        </Content>
        {/* <Ratings>
          <img src={pj} />
          <Rating>4.8/5</Rating>
          <SVG src={google} />
          <Rating>4.9/5</Rating>
        </Ratings> */}
      </Container>
    </Background>
  )
}

export default Hero
