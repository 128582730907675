import React from 'react'
import styled from 'styled-components'
import { colours, device } from '../../style/variables'

export type customCard = {
  image?: any
  price?: string
  isHouse?: boolean
  roomNumber?: number
  surface?: number
  location?: string
  className?: string
  tags?: string[]
  onClick?: () => void
}

const Card = styled.div`
  background-color: ${colours.offwhite};
  border-radius: 20px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  height: 300px;
  overflow: hidden;
  position: relative;
  width: 290px;

  @media ${device.desktop} {
    width: 315px;
  }
`

const Image = styled.img`
  height: 140px;
  margin: 0;
  object-fit: cover;

  @media ${device.desktop} {
    height: 140px;
    width: 315px;
  }
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 150px;
  padding: 0.8rem 1.4rem 0.6rem 1.4rem;
`

const Price = styled.p`
  font-size: 23px;
  font-weight: 700;
  line-height: 23px;
  margin-bottom: 0.5rem;
  text-align: left;

  @media ${device.desktop} {
    margin: 0.6rem 0;
  }
`

const Attributes = styled.p`
  color: ${colours.grey};
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0.5rem;
  text-align: left;
`

const Highlights = styled.p`
  color: ${colours.grey};
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 0;
  margin-top: auto;
  text-align: left;

  div {
    background-color: ${colours.lightBlueDark};
    border-radius: 5px;
    color: ${colours.allowaBlue};
    font-size: 12px;
    margin-right: 0.5rem;
    padding: 2px 9px;
  }
`

function PropertyCard(props: customCard): React.ReactElement {
  return (
    <Card onClick={props.onClick}>
      <Image src={props.image} />
      <CardContent>
        <Price>{props.price}€</Price>
        <Attributes>
          {props.isHouse ? 'Maison' : 'Appartement'} · T{props.roomNumber} · {props.surface} m² ·{' '}
          {props.location}
        </Attributes>
        <Highlights>
          {props.tags?.map(tag => (
            <div>{tag}</div>
          ))}
          {/* {props.wellLocated ? <div>Bien situé</div> : ''}
          {props.bigTerrace ? <div>Grande terrasse</div> : ''} */}
        </Highlights>
      </CardContent>
    </Card>
  )
}

export default PropertyCard
